// Copyright © 2021 Move Closer

import { ISiteModel } from '@service/site'
import { RedirectCallback } from '@contract/async-data'

/**
 * Performs an automated redirection based on the given error code.
 *
 * @param errorCode - The error code for the error that happened.
 * @param route - Currently-active route (???)
 * @param site - Currently-active site.
 * @param redirect - Function responsible for redirection.
 *
 * @throws Error - if the redirection couldn't have been completed.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const automaticRedirection = (
  errorCode: string | number,
  route: string,
  site: ISiteModel,
  redirect: RedirectCallback
): void => {
  /**
   * Redirection URL associated with the given error code.
   */
  const redirectionUrl = site.get('redirections')?.errorPages?.[errorCode]

  /**
   * Determines whether there's a redirection URL assigned with the given error code.
   */
  const hasRedirectionUrlAssigned: boolean =
    typeof redirectionUrl === 'string' &&
    redirectionUrl.length > 0

  /**
   * Determines whether it's a first attempt of the automated redirection.
   */
  const isFirstAttempt: boolean = route.indexOf('?attempt') === -1

  if (!hasRedirectionUrlAssigned || !isFirstAttempt) {
    throw new Error()
  }

  redirect(`${redirectionUrl}?attempt=1`)
}
