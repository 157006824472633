




































import { Component, Mixins, Provide, ProvideReactive, Vue } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'
import { AnyObject, Container, ResourceActionFailed } from '@movecloser/front-core'
import {
  ContainerComponent,
  ContentStructure,
  ModuleComponent,
  Viewer,
  ViewerConfig
} from '@movecloser/page-builder'

import { AsyncDataContext, ErrorCallback, RedirectCallback } from '@contract/async-data'
import { IRelatedService } from '@service/related'
import { ISiteModel, ISiteService, SiteServiceType } from '@service/site'
import { Store } from '@contract/store'
import {
  ALL_CONTAINERS_MOUNTED_INJECTION_KEY,
  ContainerUi,
  InjectionCallback,
  LayoutBreakpoint,
  LayoutSlot,
  log,
  REGISTRY_RESPONSE_INJECTION_KEY,
  RegistryResponse,
  RelatedServiceType, UseNonce
} from '@/shared/modules'

import { ContentRepositoryType, IContentRepository } from '../../repositories'
import { Module } from '../../components'

import { automaticRedirection } from './ContentView.helpers'

/**
 * **MOST IMPORTANT VIEW AND COMPONENT IN THE WHOLE APP.**
 *
 * ➡️ It's being rendered under the "catch-all" route.
 * ➡️ Before it gets mounted, it tries to fetch the page's data from the API, using the URL
 * entered by the User. If the API call is a success, then the page's content's are rendered
 * using the API's response. Of course, if the API call has failed, we provide the User with the
 * applicable error page.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<ContentView>({
  name: 'ContentView',
  components: { Viewer },

  metaInfo () {
    return this.result && this.result.content
      ? {
        title: this.result.content.title,
        meta: this.result.meta
      } : {}
  },

  async asyncData (context: AsyncDataContext): Promise<AnyObject | void> {
    let { url } = context

    if (context.app.$store.getters.isMaintenanceMode) {
      return { result: null }
    }

    const container = context.app.$container as Container
    const siteService: ISiteService = container.get(SiteServiceType)
    const contentRepository: IContentRepository = container.get(ContentRepositoryType)

    url = url.replace(siteService.getActiveSiteBasePath(), '')

    try {
      const result = await contentRepository.load(url, {}, false)
      return { result }
    } catch (error) {
      // Note! This is on purpose. Decorator is execute before actual code so there's ContentView already defined.
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      ContentView.handleInvariants(
        error,
        siteService.getActiveSite(),
        context.error,
        context.app.$store,
        [...context.app.$store.getters.routesHistory].pop() ?? context.app.$route.fullPath,
        context.isServer ? context.redirect : (target: RawLocation) => {
          context.app.$router.push(target)
        }
      )

      return { result: null }
    }
  },

  created (): void {
    if (typeof this.$container === 'undefined') {
      throw new Error('ContentView.created(): FATAL! [this.$container] is [undefined]!')
    }

    this.relatedService = this.$container.get<IRelatedService>(RelatedServiceType)
    this.relatedService.storeRelated(this.result ? { ...this.result.related } : {})
  },

  mounted (): void {
    this.isMounted = true
  },

  errorCaptured (err: Error, vm: Vue, info: string): boolean | void {
    if (process.env.NODE_ENV === 'production') {
      log([err, info], 'error')
      return false
    }
  }
})
export class ContentView extends Mixins<UseNonce>(UseNonce) {
  public readonly LayoutSlot = LayoutSlot

  /**
   * Value for the `<Viewer>'s` `config` @Prop.
   */
  public readonly config: ViewerConfig = { columns: 12 }

  /**
   * Value for the `<Viewer>'s` `containerComponent` @Prop.
   */
  public readonly containerComponent: ContainerComponent = ContainerUi

  /**
   * Determines whether the component has been mounted.
   */
  @ProvideReactive(ALL_CONTAINERS_MOUNTED_INJECTION_KEY)
  public isMounted: boolean = false

  /**
   * Value for the `<Viewer>'s` `moduleComponent` @Prop.
   */
  public readonly moduleComponent: ModuleComponent = Module

  /**
   * Instance of the service that implements the `IRelatedService` interface.
   */
  public relatedService: IRelatedService | null = null

  /**
   * Resolves the dependency bound to the passed-in symbol.
   *
   * @param injectionType - The symbol that the resolved dependency is associated with.
   */
  public readonly resolveInjection: InjectionCallback = (injectionType) => {
    if (typeof this.$container === 'undefined') {
      throw new Error('App.resolveInjection(): [this.$container] is [undefined]!')
    }

    return this.$container.get(injectionType)
  }

  /**
   * Data fetched from the API.
   */
  @ProvideReactive(REGISTRY_RESPONSE_INJECTION_KEY)
  public result: RegistryResponse | null = null

  /**
   * The `LayoutBreakpoint` that should be used by the `<Viewer>` component
   * in order to display the page's content using the correct layout.
   */
  public get breakpoint (): LayoutBreakpoint {
    return this.$store.getters.isMobile ? LayoutBreakpoint.XS : LayoutBreakpoint.LG
  }

  /**
   * Determine if given slot has anything to display.
   */
  public hasStructureToDisplay (slot: LayoutSlot): boolean {
    return Object.values(this.resolveSlot(slot)).length > 0
  }

  /**
   * Determines whether the app is running on a desktop device.
   */
  @Provide()
  public get isDesktop (): boolean {
    return this.$store.getters.isDesktop
  }

  /**
   * Determines whether the app is running on a mobile phone OR a tablet.
   */
  @Provide()
  public get isMobile (): boolean {
    return this.$store.getters.isMobile
  }

  /**
   * Determines whether the app is running on a mobile phone.
   */
  @Provide()
  public get isPhone (): boolean {
    return this.$store.getters.isPhone
  }

  /**
   * Resolve given slot of content.
   */
  public resolveSlot (slot: LayoutSlot): ContentStructure {
    if (!this.result) {
      return {}
    }

    return this.result.content.slots[slot] || {}
  }

  /**
   * TODO: Description.
   *
   * @param error
   * @param site
   * @param handler
   * @param store
   * @param route
   * @param redirect
   */
  private static handleInvariants (
    error: ResourceActionFailed,
    site: ISiteModel,
    handler: ErrorCallback,
    store: Store,
    route: string,
    redirect: RedirectCallback
  ): void {
    switch (error.status) {
      case 410: {
        redirect(error.payload.redirectTo, error.payload.redirectionCode ?? 303)
        break
      }

      case 503: {
        store.dispatch('setMaintenanceMode', true)
        break
      }

      default: {
        try {
          automaticRedirection(error.status, route, site, redirect)
        } catch {
          let message: string = `[ContentView]: ${error.message}`

          if (
            typeof error.payload === 'object' &&
            error.payload !== null &&
            typeof error.payload.slug !== 'undefined'
          ) {
            message += ` for ${error.payload.slug}`
          }

          handler(message, Number(error.status))
        }
      }
    }
  }
}

export default ContentView
