// Copyright © 2021 Move Closer

import { AbstractModule } from '@movecloser/page-builder'
import { defineComponent } from '@vue/composition-api'

import { AnyModule, log, ModuleConstructor, ModuleDriver, moduleDriverUiComponentRegistry } from '@/shared/modules'

import { MissingModule } from './MissingModule.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const Module = defineComponent({
  name: 'Module',
  extends: AbstractModule,
  computed: {
    resolveInnerComponent (): ModuleConstructor {
      const driver: ModuleDriver = (this.resolvedModule as AnyModule).driver
      const component: ModuleConstructor = moduleDriverUiComponentRegistry[driver]

      if (typeof component === 'undefined') {
        log(`Module: Failed to resolve the UI component for the [ModuleDriver.${driver}] driver.`, 'error')
        return MissingModule
      }

      return component
    }
  }
})
