

















































































































































































































import { Component, Inject, Mixins, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator'
import { IEventbus } from '@movecloser/front-core'
import { interpret, State } from 'xstate'
import { throttle } from 'lodash'
import {
  AbstractSelectControlOption,
  BootstrapButton,
  BootstrapIcon,
  BootstrapImage,
  BootstrapLink,
  BootstrapTheme,
  Link
} from '@movecloser/ui-core'

import { closable, EventListener, Loading } from '../../../../../../extensions'
import { ImageFile, NavigationItem } from '../../../../../../models'
import { log } from '../../../../../../support'

import {
  flagsRegistry,
  localesHiddenText,
  SEARCH_CALLBACK_INJECTION_KEY,
  TOGGLE_NAVBAR_STATE_EVENT
} from '../../Navbar.ui.config'
import { SearchCallback } from '../../Navbar.ui.contracts'

import {
  MachineContext,
  MachineEvent,
  MachineState,
  navbarDesktopMachine
} from './NavbarDesktop.machine'
import { Identifier } from '../../../../../../contracts'

import ArrowIcon from './assets/ArrowIcon.vue'

/**
 * Default value of navbar top menu height
 */
const DEFAULT_MENU_HEIGHT = 16

/**
 * Navbar version tuned for the desktop devices.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component<NavbarDesktop>({
  name: 'NavbarDesktop',
  components: { ArrowIcon, BootstrapButton, BootstrapIcon, BootstrapImage, BootstrapLink },
  directives: { closable },

  created (): void {
    this.startMachine()
  },

  mounted (): void {
    if (this.$store.getters.isMobile) {
      return
    }

    this.hideTopLeftNavbarMenuVisibilityIfOverflows()
    this.registerWatchers()
    this.setCSSVariables()
  }
})
export class NavbarDesktop extends Mixins<EventListener, Loading>(EventListener, Loading) {
  /**
   * Links to render on the bottom of the component.
   */
  @Prop({ type: Array, required: false })
  public readonly bottomNav?: NavigationItem[]

  /**
   * Image to be rendered as the "navbar brand" (the logotype).
   */
  @Prop({ type: Object, required: false })
  public readonly brandImage?: ImageFile

  /**
   * The text to be rendered next to the "navbar brand" (the logotype).
   */
  @Prop({ type: String, required: false })
  public readonly brandText?: string

  /**
   * Link associated with the "navbar brand" (the logotype).
   */
  @Prop({ type: Object, required: false })
  public readonly brandLink?: Link

  /**
   * Currently-active locale.
   */
  @PropSync('currentLocale', { type: Number, required: true })
  public _currentLocale!: Identifier

  /**
   * Link to the erecruiter form
   */
  @Prop({ type: Object, required: false })
  public readonly erecruiterLink!: Link

  /**
   * An instance of the `EventBus` service.
   */
  @Prop({ type: Object, required: true })
  private readonly eventBus!: IEventbus

  /**
   * Available locales for the User to choose from.
   */
  @Prop({ type: Array, required: false })
  public readonly locales?: AbstractSelectControlOption[]

  /**
   * Links to render on the top of the component, on the left side.
   */
  @Prop({ type: Array, required: false })
  public readonly topLeftNav?: NavigationItem[]

  /**
   * Links to render on the top of the component, on the right side.
   */
  @Prop({ type: Array, required: false })
  public readonly topRightNav?: NavigationItem[]

  /**
   * Function that handles the search logic.
   */
  @Inject(SEARCH_CALLBACK_INJECTION_KEY)
  public readonly searchCb!: SearchCallback

  /**
   * Reference for the `.NavbarDesktop__bottom` element.
   */
  @Ref('navbarBottom')
  private readonly navbarBottomRef?: HTMLElement

  /**
   * Reference for the search input.
   */
  @Ref('searchInput')
  private readonly searchInputRef?: HTMLInputElement

  /**
   * Reference for the search toggler.
   */
  @Ref('togglerBtn')
  private readonly togglerBtnRef?: Vue

  /**
   * Reference for top left menu
   */
  @Ref('topLeftNav')
  private readonly topLeftNavRef?: HTMLElement

  /**
   * Reference for top left mobile menu
   */
  @Ref('topLeftNavMobile')
  private readonly topLeftNavMobileRef?: HTMLElement

  public readonly BootstrapTheme = BootstrapTheme

  /**
   * Data entered by the User in the search form.
   */
  public formData: { query: string | null } = { query: null }

  /**
   * Determines whether languages dropdown is currently expanded
   */
  public isLanguagesDropdownOpen: boolean = false

  /**
   * Determines whether left top links are visible
   */
  public isTopLeftNavVisible: boolean = true

  /**
   * Determines whether left top links menu is visible
   */
  public isTopLeftNavMenuVisible: boolean = false

  /**
   * Determines whether the search form is currently open.
   */
  public isSearchFormOpen: boolean = false

  /**
   * Determines whether top menu is opened (Applicable if screen size is < 1200px)
   */
  public isTopMenuOpen: boolean = false

  /**
   * Determines whether bottom menu is opened. (on desktop hamburger click)
   */
  public isBottomLinksOpen: boolean = false

  /**
   * An instance of the finite state machine.
   */
  private readonly machine = interpret(navbarDesktopMachine)

  /**
   * Current state of the machine.
   */
  private state = navbarDesktopMachine.initialState

  public shouldBePrimaryText (link: Link): boolean {
    if (
      link.target === '/obszary-zatrudnienia/bankowosc-detaliczna-' ||
      link.target === '/obszary-zatrudnienia/bankowosc-detaliczna-i-biznesowa'
    ) {
      return true
    }

    return false
  }

  /**
   * CSS class name that determines the `transform` settings for the `.NavbarDesktop__bottom` section.
   */
  public get bottomSectionTransformClassName (): string {
    switch (this.state.value) {
      case MachineState.Hidden:
      case MachineState.BrandImageOnly: {
        this.isBottomLinksOpen = false
        return '--hidden'
      }

      case MachineState.BrandImageAndBottomLinks: {
        this.isBottomLinksOpen = true
        return '--visible'
      }

      default: {
        return ''
      }
    }
  }

  public toggleLanguagesDropdown (): void {
    this.isLanguagesDropdownOpen = !this.isLanguagesDropdownOpen

    // Changing 'fill' property in scss not working properly
    const icon = document.querySelector('.NavbarDesktop__top__locale-switch svg')
    if (!icon) {
      return
    }
    const path = icon.querySelector('path')
    if (!path) {
      return
    }

    if (this.isLanguagesDropdownOpen) {
      path.setAttribute('fill', '#00834F')
    } else {
      path.setAttribute('fill', '#fff')
    }
  }

  public get remainingLocales (): AbstractSelectControlOption[] {
    if (!this.locales) {
      return []
    }

    return this.locales.filter((locale) => locale.value !== this._currentLocale)
  }

  public getHiddenText (locale: string): string {
    return localesHiddenText[locale.toLowerCase()]
  }

  /**
   * Hide top left menu if it overflows default value
   *
   * @see DEFAULT_MENU_HEIGHT
   */
  public hideTopLeftNavbarMenuVisibilityIfOverflows (): void {
    if ((typeof this.topLeftNavRef === 'undefined' || typeof this.topLeftNavMobileRef === 'undefined')) {
      return
    }

    if (this.topLeftNavRef.getBoundingClientRect().height > DEFAULT_MENU_HEIGHT) {
      this.hideTopLeftNav()
      this.showTopLeftNavMenu()
    }
  }

  /**
   * Determines whether the bottom section should have shadow (box-shadow).
   */
  public get hasBottomSectionShadow (): boolean {
    return this.state.value === MachineState.BrandImageAndBottomLinks
  }

  /**
   * Determines whether the middle section should have shadow (box-shadow).
   */
  public get hasMiddleSectionShadow (): boolean {
    return this.state.value === MachineState.BrandImageOnly
  }

  /**
   * Hides top left links menu
   */
  public hideTopLeftNavMenu (): void {
    this.isTopLeftNavMenuVisible = false
  }

  /**
   * Hides top left links
   */
  public hideTopLeftNav (): void {
    this.isTopLeftNavVisible = false
  }

  /**
   * Determines logo source on the occasion.
   */
  public get logo (): string {
    const currentLocale = this.locales?.find((locale) => locale.value === this._currentLocale)
    if (!currentLocale) {
      return ''
    }

    return require(`../../../../../../../assets/logos/${currentLocale.label.toUpperCase()}/top-employer-2024.png`)
  }

  // eslint-disable-next-line no-undef
  public localeFlag (label: string): NodeRequire {
    return flagsRegistry[label.toLowerCase()]
  }

  public get localeShortcutLabel (): string {
    return this.locales?.find((locale) => locale.value === this._currentLocale)?.label ?? ''
  }

  public get localeLabel (): string {
    const currentLocale = this.locales?.find((locale) => locale.value === this._currentLocale)

    if (!currentLocale) {
      return ''
    }

    return currentLocale.label
  }

  /**
   * Handles the `@click` event on the toggler button.
   */
  public onTogglerBtnClick (): void {
    if (this.state.value === MachineState.Visible) {
      this.toggleSearchForm()
    } else {
      this.toggleBottomSectionVisibility()
    }
  }

  /**
   * Handles the `@submit` event on the search form.
   */
  public onSubmit (): void {
    this.search()
  }

  /**
   * Displays top left links menu
   */
  public showTopLeftNavMenu (): void {
    this.isTopLeftNavMenuVisible = true
  }

  /**
   * Displays top left links
   */
  public showTopLeftNav (): void {
    this.isTopLeftNavVisible = true
  }

  /**
   * Names of the icon that should be rendered inside the toggler button.
   */
  public get togglerBtnIconName (): string {
    switch (this.state.value) {
      case MachineState.Visible: {
        return 'Search'
      }

      case MachineState.BrandImageOnly:
      case MachineState.Hidden: {
        return 'Menu'
      }

      case MachineState.BrandImageAndBottomLinks:
      default: {
        return 'Close'
      }
    }
  }

  /**
   * Toggles open/close state of topRight menu in smaller devices
   */
  public toggleTopMenuState (): void {
    this.isTopMenuOpen = !this.isTopMenuOpen
  }

  /**
   * CSS class name that determines the components `transform` settings.
   */
  public get transformClassName (): string {
    switch (this.state.value) {
      case MachineState.Hidden: {
        return '--hidden'
      }

      case MachineState.BrandImageOnly:
      case MachineState.BrandImageAndBottomLinks: {
        return '--top-section-hidden'
      }

      default: {
        return ''
      }
    }
  }

  /**
   * Closes the search form.
   */
  private closeSearchForm (): void {
    if (!this.isSearchFormOpen) {
      return
    }

    this.isSearchFormOpen = false
    this.focusSearchToggler()
  }

  /**
   * Focuses the search input.
   */
  private focusSearchInput (): void {
    if (!this.searchInputRef) {
      log([
        'NavbarDesktop.focusSearchInput(): [this.searchInputRef] is falsy!',
        this.searchInputRef
      ], 'error')
      return
    }

    this.searchInputRef.focus()
  }

  /**
   * Focuses the search toggler.
   */
  private focusSearchToggler (): void {
    if (!this.togglerBtnRef) {
      log([
        'NavbarDesktop.focusSearchToggler(): [this.togglerBtnRef] is falsy!',
        this.togglerBtnRef
      ], 'error')
      return
    }

    (this.togglerBtnRef.$el as HTMLButtonElement).focus()
  }

  /**
   * Returns the height (in px) of the component's root HTML element.
   */
  private getElHeight (): number {
    if (!this.navbarBottomRef) {
      log([
        'NavbarDesktop.getElHeight(): [this.navbarBottomRef] is falsy!',
        this.navbarBottomRef
      ], 'error')
      return 0
    }

    try {
      return this.navbarBottomRef.getBoundingClientRect().bottom
    } catch (error) {
      log(error, 'error')
      return 0
    }
  }

  /**
   * Determines whether the CSS variables are set.
   */
  private static get areCSSVariablesSet (): boolean {
    const root: HTMLElement = document.documentElement
    return getComputedStyle(root).getPropertyValue('--body-margin-top').length > 0
  }

  /**
   * Last known position of the window scroll.
   */
  private lastKnownScrollPosition: number = 0

  /**
   * Handles the `keydown` event on the `Window` object.
   *
   * @param event - An instance of the `KeyboardEvent`.
   */
  private onKeyDown (event: KeyboardEvent): void {
    if (event.key === 'Escape' && this.isSearchFormOpen) {
      this.closeSearchForm()
    }
  }

  /**
   * Watches for changes of MachineState
   */
  @Watch('state')
  private onStateChanged (newState: State<MachineContext>): void {
    if (newState.changed) {
      this.eventBus.emit(TOGGLE_NAVBAR_STATE_EVENT, {
        newState: this.state.value
      })
    }
  }

  /**
   * Handles the `scroll` event on the `Window` object.
   */
  private onScroll (): void {
    const isOnTop: boolean = window.scrollY < 200
    const isScrollingDown: boolean = window.scrollY > this.lastKnownScrollPosition
    const isScrollingUp: boolean = window.scrollY < this.lastKnownScrollPosition

    if (isOnTop) {
      this.machine.send(MachineEvent.ScrollTop)
    } else if (isScrollingDown) {
      this.machine.send(MachineEvent.ScrollDown)
    } else if (isScrollingUp) {
      this.machine.send(MachineEvent.ScrollUp)
    }

    this.lastKnownScrollPosition = window.scrollY
  }

  /**
   * Opens the search form.
   */
  private openSearchForm (): void {
    this.isSearchFormOpen = true
    setTimeout(() => {
      this.focusSearchInput()
    })
  }

  /**
   * Registers all needed watchers (event listeners).
   */
  private registerWatchers (): void {
    this.addEventListener('keydown', this.onKeyDown.bind(this))
    this.addEventListener('scroll', throttle(this.onScroll.bind(this), 100))
  }

  /**
   * Performs the search action.
   */
  private async search (): Promise<void> {
    this.markAsLoading()

    const query: string = this.formData.query ?? ''

    try {
      await this.searchCb(query)
      this.closeSearchForm()
    } catch (error) {
      const message: string = 'NavbarDesktop.search(): Error during search!'
      log([message, error], 'error')
    } finally {
      this.markAsReady()
    }
  }

  /**
   * Sets-up the needed CSS variables.
   *
   * @recursive
   */
  private setCSSVariables (): void {
    if (NavbarDesktop.areCSSVariablesSet) {
      return
    }

    const elHeight: number = this.getElHeight()

    if (elHeight === 0) {
      setTimeout(() => {
        this.setCSSVariables()
      }, 10)
      return
    }

    const root: HTMLElement = document.documentElement

    root.style.setProperty('--body-margin-top', elHeight + 'px')
  }

  /**
   * Toggles (opens or closes) the search form.
   */
  private toggleSearchForm (): void {
    this.isSearchFormOpen ? this.closeSearchForm() : this.openSearchForm()
  }

  /**
   * Toggles the visibility of the `NavbarDesktop__bottom` section.
   */
  private toggleBottomSectionVisibility (): void {
    this.machine.send(MachineEvent.TogglerClick)
  }

  /**
   * Starts the finite state machine.
   */
  private startMachine (): void {
    this.machine
      .onTransition(state => {
        this.state = state
      })
      .start()
  }

  // Animation methods
  beforeEnter (el: HTMLElement): void {
    el.style.height = '0'
  }

  beforeLeave (el: HTMLElement): void {
    el.style.height = el.scrollHeight + 'px'
  }

  enter (el: HTMLElement): void {
    el.style.height = el.scrollHeight + 'px'
  }

  leave (el: HTMLElement): void {
    el.style.height = '0'
  }
}

export default NavbarDesktop
