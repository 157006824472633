
































































































import { Component, Inject, Mixins, Prop, PropSync, Ref } from 'vue-property-decorator'
import { DebouncedFunc, throttle } from 'lodash'
import {
  AbstractSelectControlOption,
  BootstrapButton,
  BootstrapIcon,
  BootstrapImage,
  BootstrapLink,
  Link
} from '@movecloser/ui-core'

import { ImageFile, NavigationItem } from '../../../../../../models'
import { Loading } from '../../../../../../extensions'
import { log } from '../../../../../../support'
import { Identifier } from '../../../../../../contracts'

import { SEARCH_CALLBACK_INJECTION_KEY } from '../../Navbar.ui.config'
import { SearchCallback } from '../../Navbar.ui.contracts'

/**
 * Navbar version tuned for the mobile devices.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (edited)
 */
@Component<NavbarMobile>({
  name: 'NavbarMobile',
  components: {
    BootstrapButton,
    BootstrapIcon,
    BootstrapImage,
    BootstrapLink
  },
  mounted (): void {
    this.setComponentCSSSettings()

    if (NavbarMobile.isBodyScrollLocked) {
      NavbarMobile.unlockBodyScroll()
    }
  },
  beforeDestroy (): void {
    this.clearComponentCSSSettings()
  }
})
export class NavbarMobile extends Mixins<Loading>(Loading) {
  /**
   * Links to render on the bottom of the component.
   */
  @Prop({ type: Array, required: false })
  public readonly bottomNav?: NavigationItem[]

  /**
   * Image to be rendered as the "navbar brand" (the logotype).
   */
  @Prop({ type: Object, required: false })
  public readonly brandImage?: ImageFile

  /**
   * The text to be rendered next to the "navbar brand" (the logotype).
   */
  @Prop({ type: String, required: false })
  public readonly brandText?: string

  /**
   * Link associated with the "navbar brand" (the logotype)
   */
  @Prop({ type: Object, required: false })
  public readonly brandLink?: Link

  /**
   * Link to the erecruiter form
   */
  @Prop({ type: Object, required: false })
  public readonly erecruiterLink!: Link

  /**
   * Available locales for the User to choose from.
   */
  @Prop({ type: Array, required: false })
  public readonly locales?: AbstractSelectControlOption[]

  /**
   * Links to render on the top of the component, on the left side.
   */
  @Prop({ type: Array, required: false })
  public readonly topLeftNav?: NavigationItem[]

  /**
   * Links to render on the top of the component, on the right side.
   */
  @Prop({ type: Array, required: false })
  public readonly topRightNav?: NavigationItem[]

  /**
   * Currently-active locale.
   */
  @PropSync('currentLocale', { type: Number, required: true })
  public _currentLocale!: Identifier

  /**
   * Function that handles the search logic.
   */
  @Inject(SEARCH_CALLBACK_INJECTION_KEY)
  public readonly searchCb!: SearchCallback

  /**
   * Reference to the `.NavbarMobile__middle` element.
   */
  @Ref('middleSection')
  private readonly middleSectionRef?: HTMLElement

  /**
   * Updates the CSS variables managed by this component.
   *
   * @throttled
   */
  public updateCSSVariables: DebouncedFunc<() => void> =
    throttle(this.setCSSVariables, 100)

  /**
   * Data entered by the User in the search form.
   */
  public formData: { query: string | null } = { query: null }

  /**
   * Determines whether the **BOTTOM** menu is currently open.
   */
  public isBottomMenuOpen: boolean = false

  /**
   * Determines whether the **TOP** menu is currently open.
   */
  public isTopMenuOpen: boolean = false

  /**
   * Handles the `@click` event on the bottom menu toggler.
   */
  public onBottomMenuTogglerClick (): void {
    this.toggleBottomMenu()
  }

  /**
   * Handles the `@submit` event on the search form.
   */
  public onSubmit (): void {
    this.search()
  }

  /**
   * Handles the `@click` event on the top menu "close" button.
   */
  public onTopMenuCloseBtnClick (): void {
    this.closeTopMenu()
  }

  /**
   * Handles the `@click` event on the top menu "open" button.
   */
  public onTopMenuOpenBtnClick (): void {
    this.openTopMenu()
  }

  /**
   * Clears the CSS variables managed by this component.
   */
  private clearComponentCSSSettings (): void {
    if (typeof window === 'undefined') {
      return
    }

    NavbarMobile.unsetCSSVariables()

    window.removeEventListener('resize', this.updateCSSVariables)
  }

  /**
   * Closes the bottom menu.
   *
   * @param [shouldUnlockBodyScroll=true] - Determines whether the body scroll
   *   should be unlocked when the menu is being closed.
   */
  private closeBottomMenu (shouldUnlockBodyScroll: boolean = true): void {
    this.isBottomMenuOpen = false
    if (shouldUnlockBodyScroll) NavbarMobile.unlockBodyScroll()
  }

  /**
   * Closes the top menu.
   *
   * @param [shouldUnlockBodyScroll=true] - Determines whether the body scroll
   *   should be unlocked when the menu is being closed.
   */
  private closeTopMenu (shouldUnlockBodyScroll: boolean = true): void {
    this.isTopMenuOpen = false
    if (shouldUnlockBodyScroll) NavbarMobile.unlockBodyScroll()
  }

  /**
   * Determines whether the top right links are present.
   */
  public get hasTopRightLinks (): boolean {
    return typeof this.topRightNav !== 'undefined' &&
      Array.isArray(this.topRightNav) &&
      this.topRightNav.length > 0
  }

  /**
   * Determines whether scroll of the body is locked
   */
  private static get isBodyScrollLocked (): boolean {
    return document.body.style.overflowY === 'hidden'
  }

  /**
   * Disables scrolling on the `<body>` element.
   */
  private static lockBodyScroll (): void {
    document.body.style.overflowY = 'hidden'
  }

  /**
   * Determines logo source on the occasion.
   */
  public get logo (): string {
    const currentLocale = this.locales?.find((locale) => locale.value === this._currentLocale)
    if (!currentLocale) {
      return ''
    }

    return require(`../../../../../../../assets/logos/${currentLocale.label.toUpperCase()}/top-employer-2024.png`)
  }

  /**
   * Height of the `.NavbarMobile__middle` element.
   */
  private get middleSectionHeight (): number {
    if (typeof this.middleSectionRef === 'undefined') {
      return 0
    }

    return this.middleSectionRef.getBoundingClientRect().height
  }

  /**
   * Opens the bottom menu.
   */
  private openBottomMenu (): void {
    if (this.isTopMenuOpen) {
      this.closeTopMenu(false)
    }

    this.isBottomMenuOpen = true
    NavbarMobile.lockBodyScroll()
  }

  /**
   * Opens the top menu.
   */
  private openTopMenu (): void {
    if (this.isBottomMenuOpen) {
      this.closeBottomMenu(false)
    }

    this.isTopMenuOpen = true
    NavbarMobile.lockBodyScroll()
  }

  /**
   * Performs the search action.
   */
  private async search (): Promise<void> {
    this.markAsLoading()

    const query: string = this.formData.query ?? ''

    try {
      await this.searchCb(query)
      this.closeBottomMenu()
    } catch (error) {
      const message: string = 'NavbarMobile.search(): Error during search!'
      log([message, error], 'error')
    } finally {
      this.markAsReady()
    }
  }

  /**
   * Sets-up the required CSS variables and updates them every time the `window` size has changed.
   */
  private setComponentCSSSettings (): void {
    if (typeof window === 'undefined') {
      return
    }

    this.setCSSVariables()

    window.addEventListener('resize', this.updateCSSVariables)
  }

  /**
   * Sets-up the needed CSS variables.
   */
  private setCSSVariables (): void {
    const root: HTMLElement = document.documentElement
    root.style.setProperty('--vh', (window.innerHeight * 0.01) + 'px')
    root.style.setProperty(
      '--navbar-mobile-middle-section-height',
      this.middleSectionHeight + 'px'
    )
  }

  /**
   * Toggles (opens or closes) the bottom menu.
   */
  private toggleBottomMenu (): void {
    this.isBottomMenuOpen ? this.closeBottomMenu() : this.openBottomMenu()
  }

  /**
   * Enables scrolling on the `<body>` element.
   */
  private static unlockBodyScroll (): void {
    document.body.style.overflowY = 'auto'
  }

  /**
   * Un-sets the previously set-up CSS variables.
   */
  private static unsetCSSVariables (): void {
    const root: HTMLElement = document.documentElement
    root.style.removeProperty('--vh')
  }

  // Animation methods
  beforeEnter (el: HTMLElement): void {
    el.style.height = '0'
  }

  beforeLeave (el: HTMLElement): void {
    el.style.height = el.scrollHeight + 'px'
  }

  enter (el: HTMLElement): void {
    el.style.height = el.scrollHeight + 'px'
  }

  leave (el: HTMLElement): void {
    el.style.height = '0'
  }
}

export default NavbarMobile
